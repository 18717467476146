.reset-password-page {
    background-image: url('https://images.unsplash.com/photo-1688118935892-b40fbf3b5e69?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1770&q=80');
    width: 100vw;
    height: 90vh;
  }
  
  .reset-password-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff5c;
    margin: 0px 57px;
    border-radius: 5px;
    padding: 20px;
    transform: translateY(100%);
  
    label {
      display: block;
      margin-bottom: 10px;
      font-weight: bold;
    }
  
    .input-field {
      padding: 5px;
      margin-bottom: 10px;
      width: 200px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  
    .reset-button {
      padding: 8px 16px;
      background-color: #8a2304;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
  
      &:hover {
        background-color: #8a2304b1;
      }
    }
  
    p {
      margin-top: 10px;
      font-size: 14px;
      color: #888;
    }
  
    .success-message {
      color: #28a745;
    }
  }
  
  .form{
    text-align: center;
  }

  .message-container{
    text-align: center;
    padding: 119px;
  }