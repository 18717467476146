.photo-preview {
  transition: transform 0.3s ease;
  overflow: hidden;
  width: fit-content;
  height: 200px;
  position: relative;
  transition: 0.6s all;

  &:hover {
    opacity: 0.8;
 
    .delete-button {
      display: block;
    } 
  }
}

.delete-button {
  display: none;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background-color: transparent;
  color:#ffffff;
  border: none;
  cursor: pointer;
  font-size: 23px;
}

.photo-preview img {
  height: 20.125;
  object-fit: cover;
}

.details-link {
  text-decoration: none;
  color: rgb(89, 115, 28)
}

img {
  width: 100%;
  height: auto;
  object-fit: cover;

  display: block;
  object-fit: cover;
  margin-top: 0px;
}
.photo-preview img,
.photo-preview video {
  height: 100%; 
  object-fit: cover;
}