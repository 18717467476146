.edit-page {
  height: 90vh;
}

.loggin-msg {
  display: flex;
  justify-content: center;
  font-size: 23px;
  font-family: "NHaasGroteskDSPro-65Md", sans-serif;
}

.edit-form {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: max-content;
  margin: auto;
  padding: 22px;
  border-radius: 4px;
  gap: 20px;
  background-color: #ffffff6d;

  .edit-input,
  .edit-select {
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .edit-select {
    cursor: pointer;
    width: 333px;
  }

  .edit-button {
    padding: 8px 16px;
    background-color: #784618;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: #784618bf;
    }
  }

  .edit-button.disabled {
    background-color: #bbb;
    cursor: default;
  }

  .edit-button.enabled {
    background-color: #784618;
    cursor: pointer;

    &:hover {
      background-color: #784618bf;
    }
  }
}