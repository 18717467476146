.about-container {
  padding: 20px;
  display: flex;
  justify-content: center;
  gap: 30px;

  .nav-links {
    align-items: center;
  }

  h2 {
    font-size: -webkit-xxx-large;
    margin: 0;
  }

  .contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }

  img {
    max-width: 400px;
    height: 70vh;
    display: flex;
    margin: auto;
  }
}

@media (max-width : 700px) {
  .about-container {
    flex-direction: column;
  }
}