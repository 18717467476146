.upload-preview {
  display: flex;
  flex-direction: column;
  gap: 20px;

  label {
    display: inline-block;
    padding: 8px 16px;
    background-color: #784618;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;

    &:hover {
      background-color: #784618d2;
    }
  }

  input[type='file'] {
    display: none;
  }

  video,
  img {
    max-width: 200px;
    float: right;
  }
  
  label.disabled {
    background-color: #bbb;
    pointer-events: none;
    cursor: default;
  }

  label.enabled {
    background-color: #784618;
    pointer-events: auto;
  }

  label.enabled:hover {
    background-color: #784618d2;
  }
}
