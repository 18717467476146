.app-header {
  background-color: #ffffff;
  padding: 20px;
  color: #fff;

  .btn-toggle {
    background-color: #ffffff00;
    color: #131212;
    border: none;
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    right: 1%;
  }

  .menu {
    background-color: #ffffff;
    width: 100%;
    height: 100vh;
    padding-top: 20px;
    font-size: larger;
    flex-direction: column-reverse;
    align-items: flex-end;

    position: relative;
    min-height: 50vh;
    text-align: center;
    font-weight: 300;
    font-size: 22px;
    line-height: 32px;
  }

  .icons-section {
    position: absolute;
    bottom: 120px;
    right: 50%;
    gap: 10px;
    display: flex;
    font-size: 24px;
    transform: translateY(250%);
  }
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  color: #131212;
  font-weight: bold;
  font-size: 20px;
  text-decoration: none;
  font-family: "NHaasGroteskDSPro-65Md", sans-serif;
}