.category-filter-container {
  position: fixed;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: space-around;

  .label {
    font-size: 1.2rem;
    font-weight: bold;
    margin-right: 1rem;
  }

  .category-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .category {
      padding: 0.5rem 1rem;
      font-size: 1rem;
      border-radius: 4px;
      margin-bottom: 0.5rem;
      cursor: pointer;
      background-color: transparent;
      color: #777777;
      margin-right: 0.5rem;

      &:hover {
        background-color: #ededed;
        color: #333333;
      }

      &.active {
        color: #333333;
        font-weight: bold;
        transform: scale(1.1);
      }
    }
  }
}

@media (max-width: 701px) {

  .category-filter-container {
    position: initial;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .category-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      flex-direction: row;
      align-items: center;
    }
  }
}