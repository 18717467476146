.footer {
  background-color: #fff0;
  bottom: 0;
  position: fixed;
  width: 95%;
  color: white;
  padding: 0 20px;
  border-color: #fff0;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 701px) {

  .footer {
    padding: 0 20px;
    font-size: 14px;
  }

}