.home-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: sans-serif;

  .header-homepage {
    display: flex;
    justify-content: space-between;
    text-align: center;
    color: white;
    font-size: 20px;
    font-weight: bold;
    padding: 20px;

    a {
      text-decoration: none;
      color: white;
      font-size: 18px;
    }
  }

  .hero-container {
    object-fit: cover;
    min-height: 100vh;
    min-width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all 1s ease 0s;

    .links-categories {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      transition: all 1s ease 0s;

      a {
        text-decoration: none;
        color: white;
        padding: 1.1rem;
        transition: all 1s ease 0s;
        font-family: Chivo;
        font-weight: 400;
        font-family: 'Helvetica Neue', 'Arial', 'sans-serif';
        font-size: large;
      }

      :hover {
        scale: 1.2;
        transition: all 0.25s ease 0s;
      }
    }
  }

  .icons-section {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3%;
    font-size: 150%;
    gap: 40px;
  }

  .fa-brands,
  .fa-whatsapp:before,
  .fa-envelope:before {
    cursor: pointer;
    text-decoration: none;
    color: white;
  }
}

@media (max-width : 700px) {
  .home-page {
    .grid-container {
      columns: 1;
    }
  }
}