.photo-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 300px));
    grid-gap: 8px;
    margin-bottom: 40px;
    justify-items: center;
    justify-content: center;
  }
  
  .photo-category {
    text-align: center;
  }
  
  .category-title {
    font-size: 28px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 20px;
  }
  
  .lazyload-placeholder {
    background-color: #f0f0f0; /* Placeholder background color */
  }
  