.welcome {
  background-image: url('https://res.cloudinary.com/dcwibf9o5/image/upload/v1692108532/edjycuq0cni70vlwelbi.jpg');
  width: 100vw;
  height: 90vh;

  .login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  p {
    margin: 0;
  }
}

.login-page {
  width: 100vw;
  height: 90vh;
  background-image: url('https://res.cloudinary.com/dcwibf9o5/image/upload/v1692108532/edjycuq0cni70vlwelbi.jpg');
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff5c;
  margin: 0px 57px;
  border-radius: 5px;
  padding: 20px;
  transform: translateY(100%);

  label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
  }

  .input-field {
    padding: 5px;
    margin-bottom: 10px;
    width: 200px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .submit-button {
    padding: 8px 16px;
    background-color: #8a2304;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: #8a2304b1;
    }
  }

  p {
    margin-top: 10px;
    font-size: 14px;
    color: #888;
  }
}

.login-form .logout-button {
  padding: 8px 16px;
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #c82333;
  }
}
.reset-password-page {

  .message-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .success-message {
      color: green;
      margin: 0;
    }

    .error-message {
      color: red;
      margin: 0;
    }
  }
}
