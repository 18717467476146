.gallery-page {
  margin-bottom: 3rem;
}

.container {
  margin-left: 20px;
  margin-right: 20px;
}

.section-title {
  font-size: 2rem;
  color: #372121;
  margin: 0;
  padding: 0.75em 0.25em;
  line-height: 1.1em;
}

.gallery-wrapper {
  display: flex;
  margin-top: 4%;
}

.filter-sidebar {
  flex: 0 0 15%;
  display: flex;
  justify-content: center;
}

.photo-list-container {
  flex: 1;
}

.photo-list {
  columns: 200px;
  break-inside: avoid;
  padding: 0;
}

.photo-list-item {
  list-style-type: none;
}

.photo-image, video {
  width: 100%;
  display: block;
  object-fit: cover;
  margin-top: 0px;
  margin-bottom: 20px;
  border: 1px solid black;
  box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.5);
}

video {
  max-height: 300px;
  max-width: 393px;
}

@media (max-width: 701px) {

  .gallery-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .filter-sidebar {
      display: flex;
      justify-content: center;
      position: absolute;
    }
  }

  .photo-list-container {
    margin-top: 6rem;
  }

}