.contact-us {
    background-color: #f0f0f0;
    padding: 40px 20px;
    text-align: center;
  
    .container {
      max-width: 800px;
      margin: 0 auto;
    }
  
    .section-title {
      font-size: 32px;
      margin-bottom: 20px;
      color: #333;
    }
  
    .contact-form {
      display: flex;
      flex-direction: column;
      align-items: center;
  
      input[type="text"],
      input[type="email"],
      textarea {
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
      }
  
      textarea {
        resize: vertical;
      }
  
      button[type="submit"] {
        background-color: #333;
        color: #fff;
        border: none;
        padding: 10px 20px;
        cursor: pointer;
  
        &:hover {
          background-color: #666;
        }
      }
    }
  }
  